<template>
	<div class="single-view tool-md5">
		<div class="tool-box">
			<h1>{{ ii('MD5_ENCRYPTION') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-area
						type="text"
						ref="tf"
						v-model="app.query.str"
						:placeholder="ii('INPUT_STRING')"
						@change="refreshQuery"
					></s-text-area>
				</div>
				<table class="result-tbl">
					<tr>
						<td>{{ ii('MD5_HASH') }}:</td>
						<td>
							<span class="md5-hash">{{ hash }}</span>
						</td>
						<td>
							<s-copy-btn :text="hash"></s-copy-btn>
						</td>
					</tr>
				</table>
			</div>

			<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>
		</div>
	</div>
</template>

<style lang="less">
@import "../styles/helpers";

.tool-md5 {
	strong {
		text-transform: uppercase;
		font-weight: 500;
	}
	.md5-hash {
		.monospace();
	}
}
</style>

<script>
import md5 from 'js-md5';

export default {
	computed: {
		hash() {
			return md5(this.app.query.str || '');
		}
	},
	async mounted() {
		if (!this.app.isMob) {
			this.$refs.tf.focus();
		}
	}
}
</script>
